<template>
    <div class="container privacy">
        <center><h3>PRIVACY POLICY</h3></center>
        Gemba LLC respect privacy of every user and takes adequate measures to capture, process,
        distribution of personal information within organization and third party vendors/contracts.
        <ul>
        <li>Personal Information</li>
        <li>Non Personal Information</li>
        <li>Disclosure and Transfer of personal information</li>
        <li>Feedback and Concerns</li>
        <li>Changes</li>
        </ul>
        <h4>Personal Information</h4>
        Personal information is collected to uniquely identify, locate an individual and for communication purpose.
        The information is collected from users for operational and transactional purposes only as described below:
        <ul>
        <li>To process application to activate the services opted for.</li>
        <li>To provide better support to the services offered by Gemba LLC.</li>
        <li>To understand user's requirements and design product /services.</li>
        <li>To provide resolution to the issues users might face.</li>
        <li>To provide new offers, updates, news & activity.</li>
        </ul>
        We don't collect any of personal information.
        We never collect sensitive data such as race, religion, sexual orientation, ethnicity or political affiliations.
        <h4>Non Personal Information</h4>
        This type of information is automatically captured and recorded by the systems when user is connected to our backbone.
        <ul>
        <li>Internet Protocol (IP) used to connect internet</li>
        <li>Operating System and Platform</li>
        </ul>
        <h4>Disclosure and Transfer of personal information</h4>
        Gemba LLC may use personal information for the purpose of providing services to the users, analysis,
        operation and transaction related activities only. We shall take adequate measures to ensure reasonable security practices.
        <h4>Feedback and Concerns</h4>
        While we take utmost care to safeguard the personal information
        we seek your continued support for the same. You may contact us on <a href="mailto:contact@gemba.biz">contact@gemba.biz</a> for any
        feedback or concerns you may have. We shall address your feedback or concerns instantaneously and in effective manner.
        <h4>Changes</h4>
        Gemba LLC reserve the right to change this Privacy Policy without any prior notice.
    </div>
</template>

<style scoped>
    .privacy { 
        line-height: 1.9;
        text-align: justify; 
    }

    h3 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.3;
    }

    h4 {
        font-size: 18px;
        margin-top: 25px;
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 1.3;
    }
</style>
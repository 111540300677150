<template>
    <div class="section section_yellow"> 
        <div class="container section__content" style="height: 475px;">
            <div class="section_left">
                <img src="assets/image/Polygon 1.png" alt="">
            </div>
            <div class="section_right">
                <h1 v-html='$t("learn_words_main")'></h1>
                <div class="section__link">
                    <a href="https://apps.apple.com/us/app/7-words/id1591739040" target="_blank">
                        <img src="assets/icons/appstore.svg" alt="appstore logo">
                    </a>
                </div>
            </div>
        </div>
        <div class="angle angle_dark"></div>
    </div>
    <div class="section section_dark">
        <div class="container container_column" style="height: 550px;row-gap:30px;">
            <h2 class="heading_yellow">{{ $t("expand_vocabulary") }}</h2>
            <p>{{ $t("expand_vocabulary_sub") }}</p>
            <div class="benefit">
                <div class="benefit_block">
                    <img class="benefit__img" src="assets/icons/Group 29.svg" alt="photo">
                    <h3 class="benefit__heading">{{ $t("effective") }}</h3>
                    <p class="benefit__text" v-html='$t("effective_description")'></p>
                </div>
                <div class="benefit_block" style="row-gap: 29px;">
                    <img class="benefit__img" src="assets/icons/Group 30.svg" alt="photo">
                    <h3 class="benefit__heading">{{ $t("timesaving") }}</h3>
                    <p class="benefit__text">{{ $t("timesaving_description") }}</p>
                </div>
                <div class="benefit_block">
                    <img class="benefit__img" src="assets/icons/Group 31.svg" alt="photo">
                    <h3 class="benefit__heading">{{ $t("smart") }}</h3>
                    <p class="benefit__text">{{ $t("smart_description") }}</p>
                </div>
            </div>
            <a class="link_button link" href="https://apps.apple.com/us/app/7-words/id1591739040" target="_blank">{{ $t("download") }}</a>
        </div>
        <div class="angle angle_yellow"></div>
    </div>
    <div class="section section_yellow">
        <div class="container content__block" style="padding-bottom: 140px;">
            <div class="block block__left">
                <div class="left_content">
                    <div class="block_item content_column">
                        <h3 class="item_heading">{{ $t("spelling") }}</h3>
                        <p class="item_text">{{ $t("spelling_description") }}</p>
                    </div>
                    <div class="block_item content_column">
                        <h3 class="item_heading">{{ $t("translation") }}</h3>
                        <p class="item_text">{{ $t("translation_description") }}</p>
                    </div>
                </div>
                <div class="block_medium">
                    <img src="assets/image/group 11.png" alt="photo">
                    <img src="assets/image/en_screen_1.svg" class="screenshort active" />
                    <img src="assets/image/en_screen_2.svg" class="screenshort" />
                    <img src="assets/image/en_screen_3.svg" class="screenshort" />
                </div>
            </div>
           
            <div class="block block__right">
                <div class="block_item content_column">
                    <h3 class="item_heading">{{ $t("Audition") }}</h3>
                    <p class="item_text">{{ $t("audition_description") }}</p>
                </div>
                <div class="block_item content_column">
                    <h3 class="item_heading">{{ $t("Pronunciation") }}</h3>
                    <p class="item_text">{{ $t("pronunciation_description") }}</p>
                </div>
            </div>
        </div>
        <div class="angle angle_white"></div>
    </div>
    <div class="fox__image"><img src="assets/image/Fox.svg" alt="photo"></div>
    
    <div class="section section_white">
        <div class="container container_column" style="height: 230px;">
            <h2>{{ $t("love_header") }} <i class="fa-solid fa-heart"></i></h2>
            <p class="text_center" v-html='$t("love_description")'></p>
        </div>
        <div class="angle angle_grey"></div>
    </div>
    <div class="section section_grey">
        <div class="container container_column" style="row-gap: 30px;">
            <h2 style="margin-top: -45px;">{{ $t("reviews_title") }}</h2>
            <div class="review content_column">
                <div class="star">
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                    <i class="fas fa-star"></i>
                </div>
                <h3>Muy practico</h3>
                <p>Es el segundo día , y la verdad es que el sistema que usa para aprender es muy dinámico, me gusta!</p>
                <div>
                    <p>Bruno</p>
                    <p>Jul 19, 2021</p>
                </div>
            </div>
            <div class="navigation">
               <div class="slide_nav active"></div>
               <div class="slide_nav"></div>
               <div class="slide_nav"></div>
               <div class="slide_nav"></div>
               <div class="slide_nav"></div>
            </div>
           
        </div>
        <div class="angle angle_yellow"></div>
    </div>
    <div class="section section_yellow">
        <div class="container container_column" style="row-gap: 25px;">
            <img src="assets/image/Group 7.svg" alt="logo" style="margin-top: -40px; margin-bottom: 30px;">
            <h2>{{ $t("download_and_boost") }}</h2>
            <p class="text_grey">{{ $t("download_sub") }}</p>
            <div class="section__link">
                <a href="https://apps.apple.com/us/app/7-words/id1591739040" target="_blank">
                    <img src="assets/icons/appstore.svg" alt="">
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",
    mounted() {
        let screenshots = document.querySelectorAll('.screenshort')
        let count = screenshots.length
        let index = 0
        setInterval(() => {
            screenshots[index++].classList.remove('active')
            if (index == count) { index = 0 }
            screenshots[index].classList.add('active')
        }, 4000)
    }
}
</script>

<template>
    <header class="header">
        <div class="container">
            <div class="header__menu">
                <div class="logo">
                    <router-link :to="{name: 'Main'}"><img src="assets/icons/logo.svg" alt="logo"></router-link>
                </div>
                <ul class="header__nav">
                    <li><router-link :to="{name: 'Terms'}" class="header__link link">{{ $t("terms") }}</router-link></li>
                    <li><router-link :to="{name: 'Privacy'}" class="header__link link">{{ $t("privacy") }}</router-link></li>
                </ul>
            </div>
        </div>
    </header>
</template>

<script>
export default {
    name: "HeaderMenu"
}
</script>

<style scoped>
    .header__menu {
        display: flex;
        justify-content: space-between;
    }

    .header__nav {
        width: 33%;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
    }

    .header__link {font-weight: 600;}

    .link {
        font-family: 'Montserrat';
        font-style: normal;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
    }

    @media (min-width: 320px) and (max-width: 767px) {
        .header {background-image: linear-gradient(225deg, #FFDF09, #FFDF09);}
        .header__nav {display: none;}  
        .header__menu {justify-content: flex-end;}  
        .logo {margin-bottom: 15px;}
    }
</style>

// vue - нужно, чтобы сайт работал
import { createApp, h } from 'vue'
// Наш роутер 
import router from './router'
// переводы сайта
import translations from './translations'
// Лайаут сайта (общий каркас на который будут крепиться разные элементы)
import Layout from './Layout'

const app = createApp({
    render: () => h(Layout)
})

app.use(router).use(translations).mount('#app')
// vue-i18n - модуль для транслитерации на разные языки
import { createI18n } from 'vue-i18n'

const messages = {
  en: {
    terms: 'Terms of use',
    privacy: 'Privacy policy',
    learn_words_main: 'Learn up to 15 <br>foregin words per day',
    expand_vocabulary: 'Expand your vocabulary with interval repetition technique!',
    expand_vocabulary_sub: 'It enables your brain to memorize words quickly and effectively',
    effective: 'Effective',
    effective_description: 'Memorize over 300 <br> new words a month',
    timesaving: 'Timesaving',
    timesaving_description: 'Learn only 5 minutes per day and achieve impressive results',
    smart: 'Smart and adaptable',
    smart_description: '7 Words will select optimal study intervals itself',
    download: 'Download 7 Words',
    spelling: 'Spelling',
    spelling_description: 'You will improve your skills to spell English words correctly. Everything you type from this moment will be mistake-free and easy to read',
    translation: 'Translation',
    translation_description: 'Translating from/to English won\'t be an issue for you anymore. 38 thematic sets of words for each difficulty level are waiting to be loaded into your brain',
    audition: 'Audition',
    audition_description: '7 Words pays much attention to audio training. You will understand spoken foregins already in a few weeks',
    pronunciation: 'Pronunciation',
    pronunciation_description: 'Train to pronounce English words with “Speech to text” assistant. You will gain a strong ability to speak clear understandable English',
    love_header: 'In love with thousands',
    love_description: '<span class="text_weight">2 thousand people</span> in more than <span class="text_weight">10 countries </span> have already installed 7 Words.',
    reviews_title: 'Customers about us',
    download_and_boost: 'Download and light up your language!',
    download_sub: 'It has never been easier!'
  },
  ru: {
    message: {
      hello: 'こんにちは、世界'
    }
  }
}

export default createI18n({
    locale: 'ru',
    fallbackLocale: 'en',
    messages,
})
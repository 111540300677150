<template>
    <div class="container terms">
        <center><h3>TERMS OF USE</h3></center>
        Please read following terms and conditions carefully. These terms govern your use of the website and any services provided by Gemba LLC.
        <h4>Terms</h4>
        Gemba LLC operate a website and mobile application which provides services to millions of users across the world.
        Anyone accessing and using our website or mobile application, agrees to be bound by these Terms and Conditions of Use.
        If You do not agree with any of these terms, You are prohibited from using or accessing our services.
        <h4>Use License</h4>
        Permission is granted to temporarily download one copy of the materials on the Gemba LLC website for personal, non-commercial transitory viewing only.
        This is the grant of a license, not a transfer of title, and under this license You may not:
        <ul>
        <li>modify or copy the materials or create derivative works therefrom.</li>
        <li>use the materials for any commercial purpose, or for any public display (commercial or non-commercial).</li>
        <li>attempt to decompile or reverse engineer any software contained on the Gemba LLC mobile application or website.</li>
        <li>remove any copyright or other proprietary notations from the materials.</li>
        <li>transfer the materials to another person or "mirror" the materials on any other server.</li>
        </ul>
        This license shall automatically terminate if You violate any of these restrictions and may be terminated by Gemba LLC at any time. Upon terminating Your viewing of these materials or upon the termination of this license, You must destroy any downloaded materials in Your possession whether in electronic or printed format.
        <h4>Ownership of Information</h4>
        Anyone using the our mobile applications acknowledges and understands that he/she has no right to the information he/she submits via app.
        <h4>Opt in</h4>
        By accepting these terms and conditions You agree that Gemba LLC may communicate with You at any time regarding your account and may send newsletters and surveys. You will have the option of unsubscribing from the latter.
        <h4>Fees and Cancellation</h4>
        Our apps offer monthly & annual paid subscription services. You are required to pay to use these services. These fees may increase / decrease time to time without prior notification. If at any given time, you would like to unsubscribe from using our services, please visit https://support.apple.com/en-in/HT202039.
        <h4>Links</h4>
        Gemba LLC have not reviewed all of the sites linked to our app or website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Gemba LLC of the site. Use of any such linked site is at the user's own risk.
        <h4>Site Terms of Use Modifications</h4>
        We may revise these terms and conditions at any time without notice. By using our apps and website, You are agreeing to be bound by the then current version of these Terms and Conditions.
    </div>
</template>

<style scoped>
    .terms { 
        line-height: 1.9;
        text-align: justify; 
    }

    h3 {
        font-size: 24px;
        margin-bottom: 10px;
        font-weight: 700;
        line-height: 1.3;
    }

    h4 {
        font-size: 18px;
        margin-top: 25px;
        margin-bottom: 5px;
        font-weight: 700;
        line-height: 1.3;
    }
</style>
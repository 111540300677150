<template>
    <!-- Кнопка "наверх" -->
    <UpButton />

    <!-- Менюшка сайта  -->
    <HeaderMenu />

    <!-- Контент страницы -->
    <router-view />
    
    <!-- Футер -->
    <Footer />
</template>

<script>
    import UpButton from './components/UpButton/UpButton.vue'
    import HeaderMenu from './components/HeaderMenu/HeaderMenu.vue'
    import Footer from './components/Footer/Footer.vue'

    export default {
        name: "Dashboard",
        components: {
            UpButton, HeaderMenu, Footer
        }
    }
</script>

<style>
    @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,700;1,100&display=swap');
    @import url('https://pro.fontawesome.com/releases/v5.10.0/css/all.css');
    @import 'style.css';
</style>


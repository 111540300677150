<template>
    <div class="top__button">
        <div class="return" @click="scrollTop">
            <i class="fa-angle-up"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UpButton',
    methods: {
        scrollTop() {
            window.scrollTo({top: 0, behavior: 'smooth' })
        }
    }
}
</script>

<style scoped>
    .return {
        background: #FFFFFF;
        border-radius: 50%;
        width: 50px;
        height: 50px;
        border:2px solid #FFFFFF;
        position: fixed;
        right: 45px;
        bottom: 60px;
        z-index: 999;
        cursor: pointer;
        box-shadow: 0px 0px 7px #ccc;
    }

    .fa-angle-up {
        font-family: "Font Awesome 5 Pro"!important;
        color: #2F3744;
        font-style: normal;
        font-size: 45px;
        display: flex;
        justify-content: center;
    }

    @media (min-width: 320px) and (max-width: 767px) {
        .return { right: 10px; }
    }
</style>
<template>
    <footer class="footer">
        <div class="container footer__content">
            <div class="footer__info content_column">
                <!--div class="footer_button">
                    <a class="footer__link link" href="" >Write to us</a>
                    <a class="footer__link link" href="">Write to us</a>
                </div-->
                <p>© 2022 GEMBA LLC</p>
                <router-link :to="{name: 'Terms'}" class="header__link link">{{ $t("terms") }}</router-link>
                <router-link :to="{name: 'Privacy'}" class="header__link link">{{ $t("privacy") }}</router-link>
            </div>
            <img src="assets/image/Group 13.svg">
        </div>
    </footer>
</template>

<script>
export default {
    name: "Footer"
}
</script>

<style scoped>
    .footer { background-color: #2F3744; height: 95px; }

    .footer__info {
        width: 100%;
        align-items: center;
        flex-direction: row;
        justify-content: flex-start;
        column-gap: 20px;
    }

    .footer__link {
        font-weight: 500;
        color: #D1D1D1;
        padding: 10px 15px;
        border-radius: 50px;
        border: 1px solid #D1D1D1;
    }

    .footer__link:hover {
        color: #FFDF14;
        border: 1px solid #FFDF14;
    }
    .footer__content {
        display: flex;
        justify-content: flex-end;
        padding: 10px 150px;
    }
    .footer a {
        color: #fff;
    }

    .footer__info p {
        color: #FFDE39;
    }

    .footer__content img {width: 84px; z-index: 1;}
    .footer_button {display: flex; justify-content: space-around; column-gap: 30px;}

    /*@media (min-width: 768px) and (max-width: 1279px) {
        .footer__content img {margin-right: -21px;}
    }*/

    @media (min-width: 320px) and (max-width: 767px) {
        .footer {height: auto;}
        .footer__info {width: 100%; row-gap: 20px;flex-direction: column;}
        .footer_button {flex-direction: column; row-gap: 20px;}
        .footer__content {display: flex; flex-direction: column-reverse; align-items: center;padding: 10px 20px;}
        .footer__info p {margin-top: 20px;}
    }
</style>
// vue-router - для навигации по сайту (переход по страницам)
import { createWebHistory, createRouter } from "vue-router"

import Index from './pages/Index'
import Terms from './pages/Terms'
import Privacy from './pages/Privacy'

const routes = [
    {
      path: "/",
      name: "Main",
      component: Index
    }, {
      path: "/terms-of-use",
      name: "Terms",
      component: Terms
    }, {
      path: "/privacy-policy",
      name: "Privacy",
      component: Privacy
    }
]
  
export default createRouter({
    history: createWebHistory(),
    routes,
})